.l-messenger {

	@messenger-header-height: 36px;

	top: calc(~'100% - ' @messenger-header-height);
	top: 100%;
	transform: translate(0, 100%);
	position: fixed;
	right: 0;
	z-index: 11;
	width: 250px;
	transition: transform 0.3s ease;

	@media @sm {
		right: 10vw;
	}

	&--isVisible {
		transform: translate(0, -@messenger-header-height);
	}

	&--isShown {
		transform: translate(0, -100%);
	}


	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: @messenger-header-height;
		padding: 0 5px 0 8px;
		background-color: @base-color-dark;
		color: @white;
		font-weight: 700;
		cursor: pointer;
	}
}
