.l-main-content {
    position: relative;
    overflow-x: hidden;
    background-color: @white;
    // transform: translate3d(0,0,0);

    transition: transform 0.3s ease;

    .mobile-menu--isActive & {
        transform: translate3d(-(@mobile-menu-width * 0.5),0,0);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: rgba(0,0,0,0.3);
        }
    }
}
