.l-mockups {
	padding-bottom: 0;
	background-color: @base-color-lighter;
	color: @white;

	&:before {
		background-color: @base-color-lighter;
	}

	&__images {
		text-align: center;
	}
}
