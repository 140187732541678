.c-dropdown {
    position: relative;
    &:hover .c-dropdown__list {
        display: block;
    }

    &__btn {
        text-transform: uppercase;
    }

    &__list {
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
        width: auto;
        padding: 0;
        border-radius: 3px;
        margin: 0;
        box-shadow: @box-shadow-1;
        background-color: @white;
        list-style: none;

    }

    &__item {
        padding: 0.5em 1em;
        // border-bottom: 1px solid @grey-light;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: @text-color;
        text-decoration: none;

        span {
            margin-left: 0.5em;
        }
    }
}
