.l-mobile-menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 1;
    width: @mobile-menu-width;
    height: 100%;
    padding: 10px;
    background-color: @white;
    color: @base-color-dark;
    overflow-y: scroll;
    transition: transform 0.3s ease;

    nav {
        min-height: calc(~'100vh + '@android-navbar-height);
    }

    .mobile-menu--isActive & {
        transform: translateX(-@mobile-menu-width);
    }

    &__logo {
        padding: 1em;
        border-bottom: 2px solid @base-color-lighter;
    }

    &__label {
        display: block;
        padding: 1em 0.7em 0em;
        font-size: 1.2em;
        text-transform: uppercase;
    }

    &__list {
        list-style-type: none;
        margin: 0 10px;
        padding: 1em;
    }

    &__item {
        padding: 1em 0;
        border-bottom: 1px solid @base-color-lighter;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__link {
        margin: 0 10px;
        color: @base-color-dark;
        text-decoration: none;
    }
}
