.c-section {
	position: relative;
	padding: (@section-padding / 2) 0 @section-padding;
	color: @grey-dark;

	&:before, &:after {
		content: '';
		position: absolute;
		left: -5vw;
		width: 110vw;
		height: @section-padding;
	}

	&:before {
		top: -5vh;
		top: -(@section-padding / 2);
	}

	&:after {
		bottom: -5vh;
	}

	&--top-up-slant {
		&:before {
			transform: rotate(-2deg);
		}
	}

	&--top-down-slant {
		&:before {
			transform: rotate(2deg);
		}
	}

	&--bottom-up-slant {
		&:after {
			transform: rotate(-2deg);
		}
	}

	&--bottom-down-slant {
		&:after {
			transform: rotate(2deg);
		}
	}

	&--white {
		&, &:before, &:after {
			background-color: @white;
		}
	}


	&__container {
		width: 100%;
		margin: 0 auto;
		padding: 0 1em;

		// @media @md {
		// 	max-width: @sm-width;
		// }

		@media @lg {
			max-width: @md-width;
		}

		@media @xl {
			max-width: @lg-width;
		}

		&--fullwidth {
			width: 100%;
			max-width: 100%;
		}
	}

	&__title {
		position: relative;
		text-align: center;
		padding: 0 1em 0.3em;
		margin-bottom: 1.8em;
		color: currentColor;
		text-transform: uppercase;
		font-size: 2.5rem;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 200px;
			height: 2px;
			background-color: currentColor;
			transform: translateX(-50%);
			transition: width 0.3s ease;
		}

		&:hover:after {
			width: 320px;
		}

	}
}
