@devices-color: @base-color-lighter;

.l-devices {
    color: @devices-color;

    &__intro {
        color: @grey-dark;
        font-size: 1.3em;
        max-width: 80%;
        text-align: center;
        margin: 0 auto 3em;

        small {
            display: block;
            margin: 2em auto 0;

            @media screen and (min-width: 711px) {
                display: none;
            }
        }
    }
}
