.l-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
    z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 48px;
	padding: 0 10px;
	box-shadow: @box-shadow-1;
	background-color: @base-color;
	color: @font-color;
	text-transform: uppercase;

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: opacity 0.5s ease;

		@media @md {
			opacity: 0;
		}

		&--isVisible {
			opacity: 1;
		}
	}

	img {
		max-width: 180px;
	}

	&__menu {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100%;
		display: none;
		text-align: center;
		transform: translate(-50%, -50%);

		&-link {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 7px 10px 12px;
			border-top: 5px solid transparent;
			color: @white;
			text-decoration: none;
			font-family: GrotaSans;
			font-size: 18px;
			transition: all .25s;

			&:hover {
				border-top-color: fadeout(@white, 50%);
			}

			&--isActive {
				border-top-color: @white;
			}
		}

		@media @md {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: right;

	}

	.btn {
		padding: 0.3em 1em;
	}

	&__mobile-menu-toggle {
		@media @md {
			display: none;
		}
	}

	&__lang {
		margin-right: 0.5em;
	}

	&__lang-button {
		display: none;

		.o-icon {
			font-size: 2em;
		}

		@media @md {
			display: block;
		}
	}

	&__dropdown-item--hu {
		body.hu & {
			display: none;
		}
	}

	&__dropdown-item--en {
		body.en & {
			display: none;
		}
	}

	&__login {
		span {
			display: none;

			@media @md {
				display: block;
			}
		}

		.o-icon {
			font-size: 1em;
		}


	}

	.o-icon {
		margin-right: 3px;
	}
}
