.btn {
	appearance: none;
	border: 2px solid transparent;
	background-color: transparent;
	text-align: center;
	color: inherit;
	cursor: pointer;
	padding: 0.5em 1em;
	border-radius: 3px;
	text-decoration: none;
	font-weight: normal;

	&--white {
		background-color: @white;
		color: @base-color;
	}

	&--transparent {
		background-color: transparent;
		color: currentColor;
		border-color: currentColor;
	}

	&:disabled {
		background-color: @disabled-bg-color;
		color: @disabled-color;
		cursor: default;
	}

	&--large {
		padding: 0.5em 2em;
		font-size: 1.5em;
		font-family: GrotaSans;
	}
}
