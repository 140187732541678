* {
	box-sizing: border-box;
}

html {
	height: 100%;
	max-width: 100vw;
	font-size: 100%;
	line-height: 1.4;
	overflow: auto;
	overflow-x: hidden;
}

body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: 'GrotaSans', arial, sans-serif;
	font-weight: 300;
	transition: transform 0.3s ease;
	background-color: #121212;
}

h1, h2 {
	font-weight: 300;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2.1rem;
}

h3 {
	font-size: 1.7rem;
}

img {
	max-width: 100%;
}

@keyframes scrollDown {
	0% {
		top: -40px;
		opacity: 0;
	}
	50% {
		top: 0;
		opacity: 1;
	}
	100% {
		top: 40px;
		opacity: 0;
	}
}
