@cta-color: #00bcd4 ;
@cta-color: #0097a7;
@cta-color: @base-color-lighter;

.l-cta {
    padding-bottom: 15vh;
    background-color: @cta-color;
    color: @white;

    &:before {
        background-color: @cta-color;
    }

    &:after {
        background-color: @white;
    }

    &__btn-container {
        text-align: center;
    }

    &__btn {
        font-size: 1.5em;
        padding: 0.5em 2.5em;
    }
}
