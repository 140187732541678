.l-hero {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 110vh;
	background: url('../img/onguard-login-screen-sm.jpg') no-repeat center center;
	background-size: cover;
	color: @font-color;
	&:after {
		content: '';
		position: absolute;
		left: -5vw;
		bottom: -5vh;
		height: 10vh;
		width: 110vw;
		background-color: @white;
		transform: rotate(2deg);
		z-index: 1;
	}

	&--404 {
		height: 100vh;
		&:after {
			display: none;
		}
	}

	&__title {
		font-size: 3em;
		margin: 0 auto;
		font-weight: 300;

		@media @sm {
			font-size: 4em;
		}

		@media @md {
			font-size: 5em;
		}

		@media @lg {
			font-size: 5vw;
		}
	}
	&__bold {
		font-weight: 700;
	}
	&__dot {
		color: @error-color;
	}
	&__club {
		font-size: 0.5em;
	}
	&__subtitle {
		padding: 0 1em;
		margin: 0 auto 1em;
		font-size: 1.5em;
		font-weight: 300;
		text-align: center;

		@media @sm {
			font-size: 3vw;
		}

		@media @md {
			margin-bottom: 0;
		}

		@media @lg {
			font-size: 2vw;
		}
	}

	&__login {
		@media @md {
			display: none;
		}
	}
	@scroll-down-height: 40px;
	&__scroll-down-container {
		position: absolute;
		left: 0;
		top: calc(~"100vh - "@scroll-down-height * 2);
		width: 100%;
		text-align: center;
	}
	&__scroll-down {
		position: relative;
		display: block;
		width: @scroll-down-height;
		height: @scroll-down-height;
		margin: 0 auto;
		border-bottom: 3px solid @white;
		border-right: 3px solid @white;
		transform: rotate(45deg);
		animation: scrollDown 2s infinite linear;
	}

	&__404 {
		font-size: 10em;
		font-weight: 700;
		color: @pink-color;
	}
}
