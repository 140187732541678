@footer-color: #121212;

.l-footer {
    text-align: center;
    background-color: @footer-color;
    color: @white;
    padding-bottom: 1em;
    font-size: 1.2em;

    &:after, &::before {
        display: none;
    }

    &__content {
        position: relative;
        padding: 0 1em 1em;
    }

    a {
        color: @white;
    }

	&__menus {
		position: relative;
		margin-bottom: 2em;

		&:after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: 0;
			right: 0;
			width: 80%;
			height: 2px;
			margin: 0 auto;
			background-color: #545454;
		}


		a {
			margin: 0 10px;
		}
	}
}
