@intro-color: @base-color-lighter;
// @intro-color: #9c27b0;

.l-intro {
	padding-top: @section-padding / 2;
	text-align: center;
	color: @intro-color;

	&__text {
		margin: 0 auto 2em;
		font-size: 1.5em;
		color: @grey-dark;

		@media @sm {
			width: 80%;
		}
	}

	&__item {
		width: 100%;
		float: left;
		padding: 0 1em;

		@media @md {
			width: 33.33%;
		}
	}

	.o-icon {
		font-size: 4em;
	}

	&__desc {
		color: @grey-dark;
	}

}
