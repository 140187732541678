@tooltip-width: 20px;
.c-tooltip {
    position: relative;
    display: inline-block;
    width: @tooltip-width;
    height: @tooltip-width;
    padding: 1px;
    border-radius: 50%;
    margin-left: 1em;
    font-size: 0.8em;
    font-weight: 700;
    background-color: @base-color;
    text-align: center;
    color: @white;
    cursor: pointer;

    &:after {
        content: attr(data-tooltip);
        position: absolute;
        left: 50%;
        bottom: calc(100% + 10px);

        display: none;

        padding: 0.5em;
        border-radius: 5px;
        background-color: @base-color-dark;
        white-space: nowrap;
        transform: translateX(-50%);
    }

    &:hover:after {
        display: block;
    }
}
