@features-color: #7e57c2;
// @features-color: #9575cd;
// @features-color: #4db6ac;
// @features-color: #f06292;
@features-color: @base-color-lighter;

.l-features {
    color: @features-color;
    background-color: @white;

    &:before {
        background-color: @white;
    }

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 230px;
        padding: 70px 1em 0;
        border-radius: 0 0 5px 5px;
        margin: 10px;
        text-align: center;
        background-color: @features-color;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 40%;
            width: 120%;
            transform: rotate(20deg);
            transform-origin: top right;
            background-color: @white;
            transition: all 0.3s ease;
        }

        &--isActive {
            &:after, .l-features__icon {
                opacity: 0;
            }

            &:after {
                transform: rotate(90deg);
            }
        }
    }
    &__icon {
        position: absolute;
        top: 2%;
        right: 5%;
        z-index: 3;
        font-size: 2.5em;
        color: @features-color;
        opacity: 1;
        transition: 0.3s ease opacity;
    }
    &__title {
        font-size: 1.2em;
        color: @white;
    }
    &__button {// button
        position: relative;
        z-index: 9;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: @box-shadow-1;
        outline: none;
        font-size: 25px;
    }
    &__more { // .button-wrapper
        position: absolute;
        bottom: 3px;
        right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        transform-origin: 50% 50%;
        transition: transform .3s .3s;

        &--isActive { // .clicked
            transform: rotate(90deg) translateY(-96px);
            right: 0;
            bottom: 0;
            transition: .3s all ease .6s;

            .l-features__more {
                &-layer { // .layer
                    transform: scale(15);
                    transition: .3s all ease .9s;
                }
                &-button { // main-button
                    opacity: 0;
                    transition: .3s all ease .3s;
                }
            }
        }
    }
    &__more-layer { // .layer
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background: @pink-color;
        border-radius: 50%;
        z-index: -99;
        pointer-events: none;
        transition: .3s all ease;
    }
    &__more-button { // .main-button
        background: @pink-color;
        align-self: flex-end;
        color: @white;
    }
    &__ripple { // .ripple
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -9;
        border: 1px solid @pink-color;
        border-radius: 50%;
        background: transparent;
        opacity: 1;
        transform: scale(.5);
        transition: .3s all ease;

        &--isActive { // .rippling
            animation: .3s rippling 1;
        }
    }
    &__overlay { // .layered-content
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &--isActive { // active
            opacity: 1;

            .l-features__close-button { //.close-button
                animation: .5s bounceIn;
            }

            .l-features__description {
                opacity: 1;
                transition: 0.3s all ease .6s;
            }
        }
    }
    &__description {
        color: @white;
        padding-left: 1em;
        padding-right: 1em;
        opacity: 0;

    }
    &__close-button { // .close-button
        position: absolute;
        right: 3px;
        top: 3px;
        background: @white;
        color: @pink-color;
    }
}

@keyframes rippling {
    25% {
        transform: scale(1.5);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
