@font-face {
	font-family: 'o-icon';
	//src: url('../fonts/icons/Font-O-some.ttf') format('truetype');
	src: url('../fonts/icons/Font-O-some.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
.o-icon {
	display: inline-block;
	font-weight: normal;
	font-style: normal;
	font-size: inherit;
	line-height: 1;
	color: inherit;
	font-size: 1.4em; // TODO: remove this, when the fonts are normal height
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	speak: none;
	outline: none;
	transition: all 0.25s;
	&::before {
		font-family: 'o-icon';
		font-weight: normal;
		font-style: normal;
		display: inline-block;
		text-decoration: inherit;
		// This makes sure that any parent that has a text-transform: uppercase will not change the character
		// from lowercase to uppercase
		text-transform: none;
		a & {
			display: inline-block;
			text-decoration: inherit;
		}
	}
	&__settings::before {
		content: '\0021';
	}
	&__comment::before {
		content: '\0022';
	}
	&__refresh::before {
		content: '\0023';
	}
	&__minimize::before {
		content: '\0024';
	}
	&__maximize::before,
	&__square::before {
		content: '\0025';
	}
	&__close::before,
	&__times::before {
		content: '\0026';
	}
	&__caret-up::before {
		content: '\0027';
	}
	&__caret-down::before {
		content: '\0028';
	}
	&__fuel::before {
		content: '\0029';
	}
	&__road::before {
		content: '\002A';
	}
	&__sms::before,
	&__text-message::before {
		content: '\002B';
	}
	&__image-upload::before {
		content: '\002C';
	}
	&__accident::before,
	&__possible-accident::before {
		content: '\002D';
	}
	&__id::before,
	&__identification::before {
		content: '\002E';
	}
	&__lightning::before,
	&__voltage::before {
		content: '\002F';
	}
	&__calendar::before {
		content: '\0030';
	}
	&__alarm::before {
		content: '\0031';
	}
	&__key::before,
	&__password::before {
		content: '\0032';
	}
	&__garage::before {
		content: '\0033';
	}
	&__remove::before,
	&__times-circle::before {
		content: '\0034';
	}
	&__circle::before,
	&__uncheck::before {
		content: '\0035';
	}
	&__check-circle::before {
		content: '\0036';
	}
	&__edit::before,
	&__pencil::before {
		content: '\0037';
	}
	&__toggle-visibility-off::before,
	&__visibility-off::before {
		content: '\0038';
	}
	&__toggle-visibility-on::before,
	&__visibility-on::before {
		content: '\0039';
	}
	&__log::before {
		content: '\003A';
	}
	&__tracking::before {
		content: '\003B';
	}
	&__height::before {
		content: '\003C';
	}
	&__speed::before,
	&__velocity::before {
		content: '\003D';
	}
	&__all-vehicle::before {
		content: '\003E';
	}
	&__clock::before,
	&__time::before {
		content: '\003F';
	}
	&__all-event::before {
		content: '\0040';
	}
	&__event::before {
		content: '\0041';
	}
	&__connection-lost::before,
	&__signal-lost::before {
		content: '\0042';
	}
	&__panic::before {
		content: '\0043';
	}
	&__suspicious-movement::before {
		content: '\0044';
	}
	&__temper-detection::before,
	&__tempering::before {
		content: '\0045';
	}
	&__cog::before,
	&__settings-alias::before {
		content: '\0046';
	}
	&__leave-zone::before {
		content: '\0047';
	}
	&__mobile::before {
		content: '\0048';
	}
	&__diagram::before,
	&__graph::before {
		content: '\0049';
	}
	&__telemetry::before {
		content: '\004A';
	}
	&__download::before {
		content: '\004B';
	}
	&__upload::before {
		content: '\004C';
	}
	&__clear-favorites::before {
		content: '\004D';
	}
	&__delete::before,
	&__trash::before {
		content: '\004E';
	}
	&__save::before {
		content: '\004F';
	}
	&__cut::before {
		content: '\0050';
	}
	&__copy::before {
		content: '\0051';
	}
	&__paste::before {
		content: '\0052';
	}
	&__telemetry-big::before,
	&__telemetry-zoom::before {
		content: '\0053';
	}
	&__credit-card::before {
		content: '\0054';
	}
	&__file-xls::before,
	&__xls::before {
		content: '\0055';
	}
	&__csv::before,
	&__file-csv::before {
		content: '\0056';
	}
	&__doc::before,
	&__file-doc::before {
		content: '\0057';
	}
	&__file-jpg::before,
	&__jpg::before {
		content: '\0058';
	}
	&__file-svg::before,
	&__svg::before {
		content: '\0059';
	}
	&__attach::before,
	&__attachment::before {
		content: '\005A';
	}
	&__paperplane::before,
	&__send::before {
		content: '\005B';
	}
	&__locked::before {
		content: '\005C';
	}
	&__unlocked::before {
		content: '\005D';
	}
	&__email::before,
	&__mail::before {
		content: '\005E';
	}
	&__diamond::before {
		content: '\005F';
	}
	&__onguard::before {
		content: '\0060';
	}
	&__iris::before {
		content: '\0061';
	}
	&__google::before {
		content: '\0062';
	}
	&__paypal-wide::before {
		content: '\0063';
	}
	&__paypal-condensed::before {
		content: '\0064';
	}
	&__paypal::before {
		content: '\0065';
	}
	&__windows::before {
		content: '\0066';
	}
	&__android::before {
		content: '\0067';
	}
	&__blackberry::before {
		content: '\0068';
	}
	&__apple::before {
		content: '\0069';
	}
	&__bluetooth::before {
		content: '\006A';
	}
	&__optin::before {
		content: '\006B';
	}
	&__optin-wide::before {
		content: '\006C';
	}
	&__purse::before,
	&__wallet::before {
		content: '\006D';
	}
	&__location::before,
	&__marker::before {
		content: '\006E';
	}
	&__favorite::before,
	&__star::before {
		content: '\006F';
	}
	&__internet::before,
	&__web::before {
		content: '\0070';
	}
	&__heart::before,
	&__love::before {
		content: '\0071';
	}
	&__idea::before,
	&__lightbulb::before {
		content: '\0072';
	}
	&__battery-full::before {
		content: '\0073';
	}
	&__battery-half::before {
		content: '\0074';
	}
	&__battery-low::before {
		content: '\0075';
	}
	&__battery-died::before {
		content: '\0076';
	}
	&__add:before,
	&__plus::before {
		content: '\0077';
	}
	&__minus::before,
	&__remove-2::before {
		content: '\0078';
	}
	&__semicolon::before {
		content: '\0079';
	}
	&__contact::before,
	&__v-card::before {
		content: '\007A';
	}
	// From v.1.2.0
	&__play::before {
		content: '\007B';
	}
	&__pause::before {
		content: '\007C';
	}
	&__user::before {
		content: '\007D';
	}
	&__file-add::before {
		content: '\007E';
	}
	&__file-delete::before {
		content: '\00FC';
	}
	&__arrow-down::before {
		content: '\00FD';
	}
	&__arrow-up::before {
		content: '\02C7';
	}
	&__arrow-right::before {
		content: '\02D8';
	}
	&__arrow-left::before {
		content: '\02D9';
	}
	&__basic::before,
	&__onguard-basic::before {
		content: '\02DB';
	}
	&__onguard-pro::before,
	&__pro::before {
		content: '\02DD';
	}
	&__onguard-pro-2::before,
	&__pro-2::before {
		content: '\2013';
	}
	&__bars::before,
	&__hamburger-menu::before {
		content: '\2014';
	}
	&__ellipsis-v::before,
	&__menu::before {
		content: '\2018';
	}
	&__group::before,
	&__vehicle-group::before {
		content: '\2019';
	}
	&__file::before {
		content: '\201A';
	}
	&__check::before,
	&__ok::before {
		content: '\201C';
	}
	&__info::before {
		content: '\2022';
	}
}
