
@font-face {
    font-family: 'GrotaSans';
    src: url('../fonts/grota/GrotaSans/GrotaSans.eot'); /* IE9 Compat Modes */
    src: url('../fonts/grota/GrotaSans/GrotaSans.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/grota/GrotaSans/GrotaSans.woff') format('woff'), /* Modern Browsers */
         url('../fonts/grota/GrotaSans/GrotaSans.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/grota/GrotaSans/GrotaSans.svg#GrotaSans') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'GrotaSans';
    src: url('../fonts/grota/GrotaSansLight/GrotaSansLight.eot'); /* IE9 Compat Modes */
    src: url('../fonts/grota/GrotaSansLight/GrotaSansLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/grota/GrotaSansLight/GrotaSansLight.woff') format('woff'), /* Modern Browsers */
         url('../fonts/grota/GrotaSansLight/GrotaSansLight.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/grota/GrotaSansLight/GrotaSansLight.svg#GrotaSansLight') format('svg'); /* Legacy iOS */
    font-style: normal;;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'GrotaSans';
    src: url('../fonts/grota/GrotaSansSemiBold/GrotaSansSemiBold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/grota/GrotaSansSemiBold/GrotaSansSemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/grota/GrotaSansSemiBold/GrotaSansSemiBold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/grota/GrotaSansSemiBold/GrotaSansSemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/grota/GrotaSansSemiBold/GrotaSansSemiBold.svg#GrotaSansSemiBold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}
