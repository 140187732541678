@contact-color: #212121;
// @contact-color: @base-color-lighter;
// @contact-color: #0288d1;
@contact-color: @base-color-lighter;

.l-contact {
    background-color: @contact-color;
    color: @white;
    padding-top: 10vh;
    padding-bottom: 5vh;

    &:before {
        background-color: @white;
    }

    &:after {
        background-color: @contact-color;
    }

    &__form {
        max-width: 600px;
        padding: 0 1em;
        margin: 0 auto;

        .no-js & {
            display: none;
        }
    }

    &__no-js {
        display: none;
        padding: 1em;
        margin: 0 auto 2em;
        background-color: @error-color;
        color: @white;
        font-size: 1.3em;
        text-align: center;

        .no-js & {
            display: block;
        }

        a {
            color: @white;
        }
    }

    &__container {
		display: flex;
		flex-direction: column;
		margin-bottom: 1em;
	}

	&__label {
		display: block;
		order: 1;
		font-size: 1.3em;
		font-weight: 700;

		&:after {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
			width: 100%;
			height: 100%;
			background-color: fade(@grey-dark, 70%);
			opacity: 0;
			transition: opacity 0.3s;
			pointer-events: none;
		}
	}

	&__input {
		display: block;
		padding: 10px;
		width: 100%;
		border: none;
		border-radius: 3px;
		order: 2;

		&:focus {
			box-shadow: @box-shadow-1;
			position: relative;
			z-index: 11;
			outline: none;

			& + .l-contact__label {
				span {
					position: relative;
					color: @white;
					z-index: 11;
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}

    .g-recaptcha {
        margin: 2em auto;

        > div {
            margin: auto;
        }
    }

    &__btn-container {
        text-align: center;
    }

	&__response {
		padding: 1.5em;
		margin-top: 1em;
		font-size: 1.5em;
		text-align: center;
		color: @white;

		&--isSuccess {
			border: 7px solid @base-color-light;
			background-color: @base-color;
		}

		&--isError {
			border: 7px solid @error-color-light;
			background-color: @error-color;
		}
	}

	&__required {
		font-size: 0.6em;
		vertical-align: top;
		margin-left: 5px;
		color: @error-color-light;
	}
}
