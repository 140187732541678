.l-table {
    color: @grey-dark;
    font-size: 1.1em;

    &__row {
        width: 100%;
        display: flex;
        justify-content: center;

        &:nth-child(odd) {
            background-color: #fafafa;
        }

        &--head {
            background-color: @devices-color !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .l-table__grid {
                font-size: 1.4em;
                font-weight: 700;
                color: @white;
                padding: 1em 10px;

            }
        }


    }

    &__grid {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        padding: 10px;
        // border-left: 1px solid;
        // border-right: 1px solid;
        text-align: center;

        &:first-of-type {
            flex: 2;
        }
    }

    .o-icon__check {
        color: @green;
        font-size: 1.6em;
    }

    .o-icon__close {
        color: @error-color;
        font-size: 1.6em;
    }
}

@media screen and (max-width: 710px) {
    .l-table {

        &__row {
            display: block;
            margin-bottom: 1em;
            box-shadow: @box-shadow-1;

            &--head {
                display: none;
            }

            &:nth-of-type(odd) {
                background-color: @white;
            }

            .l-table__grid {
                display: none;

                &--feature {
                    display: block;
                    text-align: center;
                }

                .no-js & {
                    display: block;
                }
            }

            &--isActive {
                .l-table__grid {
                    display: block;
                }
            }

        }

        &__row--head {
            display: none;
        }

        &__grid {
            display: block;
            margin: 0;
            text-align: right;

            &:before {
                display: block;
                float: left;
                color: @text-color;
            }

            &:nth-of-type(1) {
                background-color: @devices-color;
                color: @white;
                font-size: 1.3em;
                font-weight: 700;
            }

            &:nth-of-type(2):before {
                content: 'ONGUARD Tracker';
            }
            &:nth-of-type(3):before {
                content: 'IRIS.T100';
            }
            &:nth-of-type(4):before {
                content: 'IRIS.T110';
            }
            &:nth-of-type(5):before {
                content: 'IRIS.base';
            }
        }
    }
}
